import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, PrimaryButton, Seo } from 'components';
import Verified from 'assets/icons/verified-results-review.svg';
import { useRouter } from 'apis/history';
import { useQuizData } from 'utils/hooks';
import Header from 'components/Header';
import ResultsProgress from 'components/ResultsProgress';

const Outter = styled.section`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 3;
`;

const ResultsReviews: FC = () => {
  const [loading, setLoading] = useState(false);
  const data = useQuizData('resultsReviews');
  const { goToCheckout } = useRouter();

  const nextStep = () => {
    setLoading(true);
    goToCheckout({ discount: false });
  };
  return (
    <>
      <Seo />
      <Outter>
        <Header onBack={() => window.history.back()} sticky={false} />
        <ResultsProgress step={5} />
      </Outter>
      <Main>
        <Container>
          <Title dangerouslySetInnerHTML={{ __html: data?.label }} />
          <Subtitle dangerouslySetInnerHTML={{ __html: data?.subtitle }} />
          <ListContainer>
            {data?.list.map((item, index) => (
              <ListItem key={index}>
                <ImgContainer>
                  <StyledImg src={item.img} alt="Carnimeat users" />
                </ImgContainer>
                <TextContainer>
                  <ListText>{item.review}</ListText>
                  <BottomContainer>
                    <Name>{item.name}</Name>
                    <Verified />
                  </BottomContainer>
                </TextContainer>
              </ListItem>
            ))}
          </ListContainer>
          <ButtonContainer>
            <ContinueButtonStyled onClick={nextStep} loading={loading}>
              {data?.buttonTitle}
            </ContinueButtonStyled>
          </ButtonContainer>
        </Container>
      </Main>
    </>
  );
};

export default ResultsReviews;

const Main = styled.section`
  min-height: calc(100vh - 76px);
  background: #faf9f7;
  @media ${tablet} {
    min-height: calc(100vh - 68px);
  }
`;

const Container = styled.div`
  padding: 2rem 1rem 2rem;
  max-width: 30.75rem;
  margin: 0 auto;
  width: 100%;
  @media ${tablet} {
    padding: 1rem 1rem 6rem;
  }
`;

const Title = styled.h2`
  color: #141515;
  text-align: center;
  font-family: Inter;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  width: 100%;
  span {
    color: #4cb944;
  }
  padding-bottom: 0.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
  }
`;

const Subtitle = styled.p`
  color: #141515;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-top: 0.5rem;
  width: 100%;
  text-align: center;
  span {
    font-weight: 700;
  }
  padding-bottom: 1.5rem;
  @media ${tablet} {
  }
`;

const TextContainer = styled.section`
  display: flex;
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ButtonContainer = styled.section`
  width: 100%;
  @media ${tablet} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem;
    width: 100%;
    border-top: 1px solid #f5f5f5;
    background: #fff;
  }
`;

const ContinueButtonStyled = styled(PrimaryButton)`
  margin: 1.5rem auto 0;
  @media ${tablet} {
    max-width: 28.75rem;
    margin: 0 auto;
  }
`;
const ListContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

const ListItem = styled.section`
  display: flex;
  gap: 1rem;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 1px solid #f5f5f5;
  background: #f9f9f9;
`;

const ListText = styled.p`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const Name = styled.p`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const BottomContainer = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ImgContainer = styled.section`
  width: 6.125rem;
`;

const StyledImg = styled(DynamicImage)`
  width: 6.125rem;
  height: 100%;
`;
